<template>
  <a-spin :spinning='spinning'>
    <router-header :routerHeaderInfo="routerHeaderInfo" />
    <a-alert type="info" show-icon>
      <template #message>
        <span v-if="schoolInfo.enabled">
          以下信息暂不支持修改
        </span>
        <span v-else>
          未关联网校
        </span>
        ，请联系 <span class="alert-help" @click="codeVisible = true">专属客服</span> 配置。
      </template>
    </a-alert>
    <a-modal class="code-modal" v-model:visible="codeVisible" title="添加专属客服" :footer="null" width="400px" centered>
      <p>一对一为您答疑解惑</p>
      <img class="code-modal__img" src="@/assets/EduSohoCustomerServiceCode.png" />
      <p class="color-primary">立即扫码添加客服</p>
    </a-modal>

    <a-descriptions :column="1">
      <a-descriptions-item label="关联网校">{{schoolInfo.enabled ? '已开启' : '未开启'}}</a-descriptions-item>
      <template v-if="schoolInfo.enabled">
        <a-descriptions-item label="网校名称">{{schoolInfo.name}}</a-descriptions-item>
        <a-descriptions-item label="网校地址">{{schoolInfo.domain}}</a-descriptions-item>
        <a-descriptions-item label="Key">{{schoolInfo.accessKey}}</a-descriptions-item>
      </template>

    </a-descriptions>
  </a-spin>
</template>

<script>
import _ from "lodash";
import { defineComponent, ref, reactive } from "vue";
import { Descriptions } from "ant-design-vue";

import RouterHeader from "@/components/RouterHeader";

import corpSettingApi from "@/service/api/corpSetting";

export default defineComponent({
  name: "OnlineSchool",

  components: {
    RouterHeader,
    ADescriptions: Descriptions,
    ADescriptionsItem: Descriptions.Item,
  },

  setup() {
    const spinning = ref(true);

    const routerHeaderInfo = [
      {
        path: "/enterprise/systemSetting",
        name: "系统设置",
      },
      { name: "网校设置" },
    ];

    const codeVisible = ref(false);

    const schoolInfo = reactive({
      enabled: 0,
      name: "",
      domain: "",
      accessKey: "",
    });

    async function getSchoolInfo() {
      await corpSettingApi.getESRelationConfig().then((res) => {
        _.assign(schoolInfo, res);
        spinning.value = false;
      });
    }

    getSchoolInfo();
    return {
      spinning,
      routerHeaderInfo,

      codeVisible,
      schoolInfo,
    };
  },
});
</script>
<style lang='less' scoped>
.ant-alert-info {
  margin-bottom: @spacing-6x;
  width: 480px;
  background: #e9f6ff;
  .border(@color: @color-primary);
  .border-radius();

  :deep(.ant-alert-message) {
    color: @gray-dark;
  }
  .alert-help {
    padding: 0 @spacing-1x;
    color: @color-primary;
    cursor: pointer;
  }
}

:deep(.ant-descriptions-item) > span {
  justify-content: flex-end;
}
:deep(.ant-descriptions-item-label) {
  color: @gray-dark;
  min-width: 80px;
}
:deep(.ant-descriptions-item-content) {
  .font-14();
  color: @gray-darker;
}
</style>